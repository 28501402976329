#login-container .page-content {
  padding: 40px 0px;
  background-repeat: repeat;
  background-position: center center;
  background-color: #F9FAFB;
  background-image: url("/static/loginbg.png");
  min-height: calc(100vh - 56px); }

#login-container .container.small .col-12 {
  padding: 2em;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

#login-container form {
  margin-top: 40px;
  font-size: 1.25em; }

#login-container p a {
  text-decoration: underline; }

#login-container .switch-message {
  margin-top: 25px;
  opacity: 0.8;
  display: block; }
